import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/Layout"
import SEO from "../components/SEO"
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import { makeStyles } from "@material-ui/core/styles";
import Pagination from '@material-ui/lab/Pagination';
import Sidebar from '../components/Sidebar'
import Img from "gatsby-image"
import Avatar from '@material-ui/core/Avatar'
import Card from '@material-ui/core/Card'
import CardHeader from '@material-ui/core/CardHeader'
import CardActionArea from '@material-ui/core/CardActionArea';
import Fade from '@material-ui/core/Fade';
import PlaceholderImage from '../content/blogposts/images/blogicon.png'

export default function BlogPage({ data }) {

const useStyles = makeStyles(theme => ({
  heroContent: {
    background: 'radial-gradient(circle, rgba(251,233,231,1) 0%, rgba(221,213,213,1) 87%, rgba(224,242,241,1) 100%)',
    //backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0.1)), url(${BackgroundImage})`,
    //backgroundColor: '#7fc7d9', // Average color of the background image.
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    //height: '100vh',
    //backgroundRepeat: 'no-repeat',
    padding: theme.spacing(8, 0, 6),
    position: 'relative',
    top:0,
    left: 0,
    paddingTop: 70
  },
  blogContainer: {
    padding: 10
  },
  link: {
    textDecorationLine: 'none'
  },
  avatar: {
    width: 100,
    height: 100,
    borderRadius: '50%'
  },
  smalltitle: {
    color: theme.palette.common.white,
  }
}));

const classes = useStyles();

const itemsPerPage = 7;

const [pageIndex, setPageIndex] = React.useState(1);
const handleIndex = (event, value) => setPageIndex(value);

  return (
    <Layout>
      <SEO title="Blog" />

      <div className={classes.heroContent} >

      <Grid container className={classes.blogContainer}>

      <Hidden smDown>
        <Grid item xs={3} >
        <Sidebar />
        </Grid>
      </Hidden>
      <Grid item xs={12} md={9}>
        {data.allMdx.edges.length>itemsPerPage?<div style={{display:'flex', justifyContent: 'center'}}>
          <Pagination count={Math.trunc(data.allMdx.edges.length/itemsPerPage)+1} page={pageIndex} onChange={handleIndex} />
        </div>:null}

      {data.allMdx.edges.slice((pageIndex-1) * itemsPerPage, (pageIndex) * itemsPerPage)
        .map(({ node }, i) => {
          let featuredImgFluid = null

          if (node.frontmatter.featuredImage){
          featuredImgFluid = node.frontmatter.featuredImage.childImageSharp.fluid;}

          return (
          <div key={node.id}>

            <Link
              to={node.frontmatter.path}
              underline="none"
              color="inherit"
              className={classes.link}
            >
            <Fade in={true} style={{ transitionDelay: `${(i+1)*200}ms` }}>
              <Card elevation={0} style={{backgroundColor: 'transparent'}}>
              <CardActionArea>
                <CardHeader avatar={featuredImgFluid?<Img fluid={featuredImgFluid} className={classes.avatar} alt='Featured Image' title= 'Featured Image'/>:<Avatar src={PlaceholderImage} alt='placeholder image' className={classes.avatar}/>}
                title={<Typography variant='h6'>{ node.frontmatter.title}</Typography>}
                subheader={node.excerpt} />
                </CardActionArea>
              </Card>
            </Fade>
              </Link>
          </div>
        )})}
        {data.allMdx.edges.length>itemsPerPage?<div style={{display:'flex', justifyContent: 'center'}}>
          <Pagination count={Math.trunc(data.allMdx.edges.length/itemsPerPage)+1} page={pageIndex} onChange={handleIndex} />
        </div>:null}
      </Grid>

      </Grid>

        </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {status: {eq: "publish"}, type: { eq: "blog"}}}

    ) {
      totalCount
      edges {
        node {
          id
          frontmatter {
            title
            date
            rawDate: date
            path
            featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              ...GatsbyImageSharpFluid
            }
          }
        }
          }
          fields {
            slug
          }
          excerpt
        }
      }
    }
  }
`
