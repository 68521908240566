import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Typography from '../atoms/Typography';
import InformativeImage from '../images/smartphone.png'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import TwitterIcon from '@material-ui/icons/Twitter';
import FacebookIcon from '@material-ui/icons/Facebook';

const styles = theme => ({
  root: {
    display: 'flex',
    overflow: 'hidden',
    backgroundColor: 'transparent',
    position: 'sticky',
    zIndex: 5,
    top: 0,
    left: 0
  },
  card: {
    display: 'flex',
    flexDirection:'column',
    backgroundColor: 'transparent',
  },
  container: {
    marginTop: theme.spacing(15),
    marginBottom: theme.spacing(15),
    display: 'flex',
    position: 'relative',
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0, 5),
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    height: 70
  },
  image: {
    width: 50,
        height: 50
  },
  content: {
    textAlign:'center'
  },
  actions: {
    display: 'flex',
    justifyContent:'center',
  },
  twittericon: {
    color: '#00acee'
  },
  facebookicon: {
    color: '#3b5998'
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
  },
  curvyLines: {
    pointerEvents: 'none',
    position: 'absolute',
    top: -180,
    left: -130,
    opacity: 0.1
  },
});

function Sidebar(props) {
  const { classes } = props;

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
      <Card elevation={0} className={classes.card}>
      <div className={classes.header}>
        <Avatar src={InformativeImage}  className={classes.image}/>
      </div>
      <CardContent className={classes.content}>
        <Typography gutterBottom variant="h5" component="h2">
          Blog
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          A place for Interesting stories, tips, snippets, trivia and more.
        </Typography>
      </CardContent>

    <CardActions className={classes.actions}>
      {/*<IconButton  size="small"  className={classes.twittericon} href={'https://twitter.com/thevusiem'}>
        <TwitterIcon/>
      </IconButton>
      <IconButton size="small" className={classes.facebookicon} href={'https://facebook.com/thevusiem'}>
        <FacebookIcon/>
  </IconButton>*/}
    </CardActions>
  </Card>

      </Container>
    </section>
  );
}

Sidebar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Sidebar);
